import { useContext } from "react";
import Layout from "../components/layout";
import { WebsiteContext } from "../components/website-context";

export default function Animation() {
    const { text } = useContext(WebsiteContext);

    return <Layout title={text["animation_title"]}>

                <h2 className="text-2xl my-3">{text["animation_title_2"]}</h2>

                <div className="flex flex-wrap">
                    <div className="basis-3/5 max-md:basis-full">
                        {
                            text["animation_text"]?.map((txt, idx) => {
                                return txt.title ?
                                            <h2 key={idx} className="text-2xl uppercase mt-4">{txt.title}</h2>
                                        :
                                            <p key={idx}>{txt.text}</p>
                            })
                        }
                    </div>
                    <div className="basis-2/5 max-md:basis-full">
                        <div className="ml-4 max-md:ml-0">
                            <img src="/img/animation.jpg" alt="Animation métier ancien" className="max-h-160" />
                        </div>
                    </div>
                </div>
            </Layout>

}