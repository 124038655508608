import { useContext, useEffect } from "react";
import Layout from "../components/layout";
import { WebsiteContext } from "../components/website-context";

export default function Testimonials() {
    const { text } = useContext(WebsiteContext);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://widgets.sociablekit.com/google-reviews/widget.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [])

    return <Layout title={text["testimonials_title"]} description={text["testimonials_description"]}>
                <div className="pointer-events-none flex justify-center">
                    <div className='sk-ww-google-reviews' data-embed-id='25445665'></div>
                </div>
            </Layout>

}