const lang = {
    "main_title_1" : "Tressage et cannage",
    "main_title_2" : "Renée Nadon",
    "main_title_3" : "Artisane rempailleuse",
    "home_title" : "Tressage et cannage",
    "about_title" : "À propos",
    "contact_title" : "Contact et demande de soumission",
    "animation_title" : "Animation",
    "testimonials_title" : "Témoignages",

    "home_description" : "Tressage spécialisé oeuvrant dans la région de Montréal, Laval, Basses-Laurentides et environs.",
    "contact_description" : "Pour toutes questions ou pour obtenir un devis, veuillez communiquer avec nous par téléphone ou par courriel.",

    "home_link" : "Accueil",
    "home_text_1" : [
        {"text" : "Le style moderne du milieu du 20e siècle (mid-century modern) offrait des chaises et fauteuils aux lignes épurées associé au mouvement du design danois ou italien. Certains de ces meubles avaient une assise ou un dossier tressé. Un peu plus tard dans les années 70, la mode était au mobilier de cuisine plus rustique avec des chaises capucines ayant un siège tressé en rotin, babiche, foin de mer ou corde de papier."},
        {"text" : "Afin de préserver ces magnifiques trésors, je peux en refaire le siège tout en respectant le design et le matériel d'origine."},
        {"text" : "Depuis 2007, en reprenant les méthodes traditionnelles, je vous propose mes services de cannage, tressage et de rempaillage. Un travail soigné et effectué avec passion dans mon atelier de restauration."}
    ],

    "animation_title_2" : "Animation métier ancien : Canneur - rempailleur",
    "animation_text" : [
        {"text" : "Vêtue d'un costume d'époque, je peux animer un atelier permettant de découvrir les techniques utilisées lors du travail de restauration de sièges, soit le rempaillage ou le cannage à la main."},
        {"text" : "Entouré de chaises antiques sur lesquelles les intéressés peuvent se reposer, j'effectue en continue le travail de restauration en leur expliquant ce métier ancestral."},
        {"text" : "Il me fait plaisir de partager le savoir de ce métier traditionnel pratiqué par nos ancêtres, qui utilisaient des matériaux naturels disponibles dans leur environnement, telles que la babiche, l'écorce d'orme, la clisse de frêne, la corde, etc."}
    ],

    "contact_text_1" : "L'atelier est situé à Deux-Montagnes (Basses-Laurentides) à moins de 30 minutes de Montréal. Pour de plus amples informations ou pour connaître l'adresse de l'atelier, contactez Mme Renée Nadon.",

    "contact_form_title" : "Demande de soumission",
    "contact_form_success" : "Merci, votre courriel a été envoyé",
    "contact_form_wait" : "Veuillez patienter pendant que nous traitons votre demande",
    "name" : "Nom",
    "email" : "Courriel",
    "phone" : "Téléphone",
    "message" : "Message",
    "Photo input" : "Vous pouvez téléverser une photo de l'objet que vous aimeriez faire restaurer (en format JPG ou PNG, maximum 5MB)",

    "" : ""
}

export default lang;