const lang = {
    "main_title_1" : "Caining and weaving",
    "main_title_2" : "Renée Nadon",
    "main_title_3" : "Craftsman",
    "home_title" : "Caining and weaving",
    "about_title" : "About",
    "contact_title" : "Contact and quote request",
    "animation_title" : "Animation",
    "testimonials_title" : "Testimonials",

    "home_description" : "Specialized weaving workshop working in the region of Montreal, Laval, Basses-Laurentides and surrounding areas.",
    "contact_description" : "For any questions or for a quote, please contact us by phone or email.",

    "home_link" : "Home",
    "home_text_1" : [
        {"text" : "The modern style of the mid-20th century offered chairs and armchairs with clean lines associated with the Danish or Italian design movement, and some of these furniture pieces had a woven seat or backrest. A little later in the seventies, the trend was for more rustic-style kitchen furniture with rush-seated chairs, also with a woven seat made of rattan, babiche, sea grass, or paper cord."},
        {"text" : "In order to preserve these beautiful treasures, I can redo the seat while respecting the original design and material."},
        {"text" : "Since 2007, by returning to traditional methods, I offer my services in caining, weaving, and re-pailing. A meticulous job done with passion in my restoration workshop."}
    ],

    "animation_title_2" : "Animation of old profession: Caneer - re-pailer",
    "animation_text" : [
        {"text" : "Dressed in period costume, I can animate a workshop where attendees discover the techniques used in the restoration of seats, either re-pailing or hand-caning."},
        {"text" : "Surrounded by antique chairs on which interested individuals can sit, I work on a chair while explaining this ancient profession to them."},
        {"text" : "I am happy to share the knowledge of this traditional profession practiced by our ancestors, who used natural materials available in their environment, such as babiche, elm bark, ash splint, rope, etc."}
    ],

    "contact_text_1" : "The workshop is located in Two Mountains (Lower Laurentians) less than 30 minutes drive from Montreal. For more information, contact Ms. Renee Nadon",

    "contact_form_title" : "Quote request",
    "contact_form_success" : "Thank you, your email has been sent",
    "contact_form_wait" : "Please wait while we process your request",
    "name" : "Name",
    "email" : "Email",
    "phone" : "Phone",
    "message" : "Message",
    "Photo input" : "You can upload a photo of the item you would like to have restored (in JPG or PNG format, maximum 5MB)",

    "" : ""
}

export default lang;