import { useContext, useState } from "react";
import Layout from "../components/layout";
import { WebsiteContext } from "../components/website-context";
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';

export default function Contact() {
    const { text } = useContext(WebsiteContext);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(null);
    const [showPleaseWait, setShowPleaseWait] = useState(null);

    const position = {
        lat: 45.53960153951361,
        lng: -73.90872001647949,
        zoom: 12
    };

    const submitForm = (e) => {
        e.preventDefault();
        setShowErrorMessage(null);
        setShowSuccessMessage(false);
        setShowPleaseWait(true);

        window.grecaptcha.ready(async () => {
            const token = await window.grecaptcha.execute('6LfwMB8qAAAAAIq7kZi7ZFbvQLAQcFDYI2EC2PCu', {action: 'LOGIN'});
            const form = e.target;
            const data = new FormData(form);
            data.append("g-recaptcha-response", token);

            fetch("/contact.php", {
                method: "POST",
                body: data,
            })
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "error") {
                    setShowErrorMessage(result.message ?? "Erreur lors de l'envoi du formulaire");
                }
                else {
                    form.reset();
                    setShowSuccessMessage(true);
                }

                setShowPleaseWait(false);
            })
            .catch((error) => {
                setShowErrorMessage("Erreur lors de l'envoi du formulaire");
                setShowPleaseWait(false);
                console.error(error);
            });
        });
    }

    return <Layout title={text["contact_title"]} description={text["contact_description"]}>
                <div className="flex flex-wrap gap-5">
                    <div className="basis-3/5 max-md:basis-full">
                        <p>{text["contact_text_1"]}</p>
                        <p>

                            <a className="font-semibold" href="mailto:reneenadon@gmail.com">reneenadon@gmail.com</a><br />
                            <a className="font-semibold" href="tel:514-704-0875">514-704-0875</a>
                        </p>
                        <p>{text["contact_text_2"]}</p>

                        <div className="mt-6 border border-black p-2">
                            <h2 className="text-2xl">{text["contact_form_title"]}</h2>
                            {
                                showSuccessMessage &&
                                    <div className="bg-green-200 border border-green-500 p-2 mt-2">
                                        {text["contact_form_success"]}
                                    </div>
                            }
                            {
                                showErrorMessage &&
                                    <div className="bg-red-200 border border-red-500 text-red-800 p-2 mt-2">
                                        {showErrorMessage}
                                    </div>
                            }
                            {
                                showPleaseWait &&
                                    <div className="bg-yellow-200 border text-yellow-600 border-yellow-500 p-2 mt-2">
                                        {text["contact_form_wait"]}
                                    </div>
                            }
                            <form onSubmit={submitForm} method="POST">
                                <input type="hidden" name="form-name" value="contact" />
                                <div className="flex flex-wrap gap-5">
                                    <div className="basis-1/2">
                                        <label htmlFor="name">{text["name"]}</label>
                                        <input type="text" name="name" id="name" className="w-full border border-gray-500" required />
                                    </div>
                                    <div className="basis-1/2">
                                        <label htmlFor="email">{text["email"]}</label>
                                        <input type="email" name="email" id="email" className="w-full border border-gray-500" required />
                                    </div>
                                    <div className="basis-1/2">
                                        <label htmlFor="email">{text["phone"]}</label>
                                        <input type="tel" name="phone" id="email" className="w-full border border-gray-500" required />
                                    </div>
                                    <div className="basis-full">
                                        <label htmlFor="message">{text["message"]}</label>
                                        <textarea name="message" id="message" className="w-full border h-38 border-gray-500" required></textarea>
                                    </div>
                                    <div className="basis-full">
                                        <label htmlFor="photo">{text["Photo input"]}</label>
                                        <div>
                                            <input type="file" name="photo" id="photo" className="my-2 border border-gray-500" accept="image/jpeg, image/png" />
                                        </div>
                                    </div>
                                    <div className="basis-full text-center">
                                        <button type="submit" disabled={showPleaseWait}
                                                style={{backgroundColor: "#7A4316"}}
                                                className="hover:opacity-50 disabled:opacity-50 text-white font-bold py-2 px-4 rounded">Envoyer</button>
                                    </div>
                                </div>
                            </form>
                            <div className="text-xs mt-5">
                                This site is protected by reCAPTCHA and the <a target="google" href="https://policies.google.com/privacy">Google Privacy Policy</a> and
                                <a target="google" href="https://policies.google.com/terms">Terms of Service</a> apply.
                            </div>
                        </div>
                    </div>
                    <div className="grow h-96 max-md:basis-full relative">
                        <APIProvider apiKey={'AIzaSyD4_BFOk-nJKvlYGkQZSGt5bF2HWQaFDjM'}>
                            <Map defaultCenter={position} defaultZoom={10}>
                                <Marker position={position} />
                            </Map>
                        </APIProvider>
                    </div>
                </div>
            </Layout>

}