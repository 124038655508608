import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { WebsiteContext } from './website-context';
import { Link, useLocation } from "react-router-dom";

export default function Layout({ title, description, children, mainImage = null }) {
    const location = useLocation();
    const { lang, text } = useContext(WebsiteContext);
    const [menuOpen, setMenuOpen] = useState(false);

    const siteTitle = (title === text["home_title"] ? "" : (title + " | ")) + (lang === "fr" ? "Tressage et cannage Renée Nadon" : "Caining and weaving Renée Nadon");

    return (<>
            <Helmet htmlAttributes={{ lang : lang }}>
                <title>{siteTitle}</title>
                <link rel="canonical" href={"https://tressage.ca" + location.pathname} />
            </Helmet>
            <div className='flex flex-col min-h-screen'>
                <header className="p-4 border-b-2 flex justify-between items-center sticky top-0 z-20 bg-white">
                    <div className='flex gap-5 left-text mr-2'>
                        <div>
                            <Link to={lang === "fr" ? "/" : "/en"}>
                                <img src="/img/tressage-2.jpg" alt="Tressage de chaise" className="h-24 w-24 max-sm:h-16 max-sm:w-16" />
                            </Link>
                        </div>
                        <div className='font-semibold flex flex-col justify-between'>
                            <div className='text-3xl max-xl:text-2xl max-md:text-xl'>
                                {text["main_title_1"]}
                            </div>
                            <div>
                                <div className='text-lg max-md:text-sm'>
                                    {text["main_title_2"]}
                                </div>
                                <div className='text-md font-normal max-md:text-sm'>
                                    {text["main_title_3"]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button
                            className={"focus:outline-none md:hidden right-6 top-5 z-50 " + (menuOpen ? "fixed" : "absolute")}
                            onClick={() => setMenuOpen(!menuOpen)}
                        >
                            <svg
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                {menuOpen ? (
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                ) : (
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                )}
                            </svg>
                        </button>
                        <nav className=''>
                            <ul className={"flex max-lg:gap-10 gap-5 text-center " + (menuOpen ? "max-md:flex max-md:pt-20 max-md:w-48 max-md:gap-3 fixed z-40 right-0 top-0 h-full p-6 flex-col border-l-2 shadow bg-white" : "max-md:hidden")}>
                                <li className={location.pathname === "/" || location.pathname === "/en" ? "font-bold" : ""}><Link to={lang === "fr" ? "/" : "/en"}>{text["home_link"]}</Link></li>
                                <li className={location.pathname === "/temoignages" || location.pathname === "/en/testimonials" ? "font-bold" : ""}><Link to={lang === "fr" ? "/temoignages" : "/en/testimonials"}>{text["testimonials_title"]}</Link></li>
                                <li className={location.pathname === "/animation" || location.pathname === "/en/animation" ? "font-bold" : ""}><Link to={lang === "fr" ? "/animation" : "/en/animation"}>{text["animation_title"]}</Link></li>
                                <li className={location.pathname === "/contact" || location.pathname === "/en/contact" ? "font-bold" : ""}><Link to={lang === "fr" ? "/contact" : "/en/contact"}>{text["contact_title"]}</Link></li>
                                {
                                    lang === "fr" ?
                                        <li><a href="/en">English</a></li>
                                    :
                                        <li><a href="/">Français</a></li>
                                }
                            </ul>
                        </nav>
                    </div>
                </header>
                {
                    mainImage ?
                        <div className='relative overflow-hidden'>
                            <div className='absolute h-full w-full blur-md cover-image bg-repeat-x z-0 ' style={{backgroundImage : "url('" + mainImage + "')"}}></div>
                            <div className='h-120 max-md:h-96 relative mx-auto cover-image z-10 max-w-screen-xl blur-none ' style={{backgroundImage : "url('" + mainImage + "')"}}></div>
                        </div>

                    :
                        null
                }
                <div className='max-w-screen-xl mx-auto p-5 grow w-full'>
                    <main>
                        <div>
                            <h1 className='text-3xl font-bold mb-4'>{title}</h1>
                            {children}
                        </div>
                    </main>
                </div>
                <footer className='border-t shadow w-full'>
                    <div className='max-w-screen-xl mx-auto p-5 text-center text-sm max-lg:text-xs opacity-70 flex justify-between gap-10'>
                        <div className='text-left flex gap-2 max-sm:flex-col-reverse max-sm:gap-0'>
                            <div>&copy;{ new Date().getFullYear() }</div>
                            <div>Tressage et cannage Renée Nadon</div>
                        </div>
                        <div className='flex gap-2 max-sm:block text-right'>
                            <div>
                                <a href="mailto:reneenadon@gmail.com">reneenadon@gmail.com</a>
                            </div>
                            <div className='max-sm:hidden'>|</div>
                            <div>
                                <a href="tel:514-704-0875">514-704-0875</a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}