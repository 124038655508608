import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './css/global.css';
import Home from './pages/home';
import { WebsiteContextProvider } from './components/website-context';
import { HelmetProvider } from 'react-helmet-async';
import Contact from './pages/contact';
import NotFound from './pages/not-found';
import Testimonials from './pages/temoignages';
import Animation from './pages/animation';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<HelmetProvider>
			<WebsiteContextProvider>
				<BrowserRouter basename={process.env.PUBLIC_URL}>
					<Routes>
						<Route path='*' element={<NotFound />} />
						<Route path="/" element={<Home />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/temoignages" element={<Testimonials />} />
						<Route path="/animation" element={<Animation />} />

						<Route path="/en" element={<Home />} />
						<Route path="/en/contact" element={<Contact />} />
						<Route path="/en/testimonials" element={<Testimonials />} />
						<Route path="/en/animation" element={<Animation />} />
					</Routes>
				</BrowserRouter>
			</WebsiteContextProvider>
		</HelmetProvider>
  </React.StrictMode>
);
