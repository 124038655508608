import React, { useEffect, useState } from 'react';
import fr from "../lang/fr";
import en from "../lang/en";

export const WebsiteContext = React.createContext({
	lang: "",
	setLang : () => {},
});

export const WebsiteContextProvider = (props) => {
  	const [lang, setLang] = useState(window.location.pathname?.startsWith("/en") ? "en" : "fr");
	const [text, setText] = useState({});

  	useEffect(() => {
    	if (lang === "fr") {
			setText(fr)
		}
		else {
			setText(en)
		}
  	}, [lang])

  	return (
    	<WebsiteContext.Provider value={{ 	lang : lang,
											setLang : setLang,
											text : text
										}}>
      		{props.children}
    	</WebsiteContext.Provider>
	);
};